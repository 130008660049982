// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import * as mediator from '@tvg/mediator-classic/src';
import { options } from '../../models/filterFavoriteOptions';
import { getContextStyle } from '../../factories/styleContext';
import styleDesktop from './style.desktop.css';
import styleMobile from './style.mobile.css';
import RaceFilterTittle from '../RaceFilterTittle';
import RaceFilterOptionsGroup from '../RaceFilterOptionsGroup';

type Props = {
  context: string,
  values: Array<string>,
  updateGroup: (par: string) => void,
  closeComp: () => void,
  openTooltip: () => void,
};

class RaceFilterFavorites extends Component {
  constructor(props: Props) {
    super(props);

    this.style = getContextStyle(this.props.context, styleDesktop, styleMobile);

    _.bindAll(this, [
      'navigateToFavoriteTracksTVG4',
      'navigateToFavoriteTracksTouch',
    ]);
  }

  props: Props;

  mediatorActions = {
    'TVG4': {
      'NAV': 'TVG4_NAVIGATION'
    },
    'TOUCH': {
      'NAV': 'TVG4_NAVIGATION'
    }
  };

  navigateToFavoriteTracksTVG4() {
    mediator.dispatch(this.mediatorActions.TVG4.NAV, {
      route: '/my-favorite-tracks',
    });

    mediator.dispatch('RACE_FILTER_FAVORITES_NAVIGATION', {});

    mediator.dispatch('CLOSE_HAMBURGUER', {});

    this.props.closeComp();
  }

  navigateToFavoriteTracksTouch() {
    this.props.openTooltip();
    mediator.dispatch(this.mediatorActions.TOUCH.NAV, {
      route: '/tracks/bytrack',
    });

    this.props.closeComp();
  }

  render() {
    return (
      <div className={this.style.container}>
        <RaceFilterTittle
          context={this.props.context}
          title="Favorites"
        />
        {this.props.context === 'lhn' &&
        <button
          className={this.style.favoriteTracksButton}
          onClick={this.navigateToFavoriteTracksTVG4}>
          SELECT TRACKS
        </button>}
        {this.props.context === 'mhdr' &&
        <button
          className={this.style.favoriteTracksButton}
          onClick={this.navigateToFavoriteTracksTouch}>
          SELECT TRACKS
        </button>}
        <RaceFilterOptionsGroup
          context={this.props.context}
          options={options}
          values={[this.props.values]}
          toggleOption={this.props.updateGroup}
        />
      </div>
    );
  }
}

export default RaceFilterFavorites;
