// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import IconClose from '@tvg/ui-bootstrap/assets/svg/close.svg';
import { getContextStyle } from '../../factories/styleContext';
import styleDesktop from './style.desktop.css';
import styleMobile from './style.mobile.css';

type Props = {
  context: string,
  closeComp: () => void,
};

class RaceFilterHeader extends Component {
  constructor(props: Props) {
    super(props);

    _.bindAll(this, [
      'renderDesktop',
      'renderMobile',
    ]);

    this.style = getContextStyle(this.props.context, styleDesktop, styleMobile);
  }
  props: Props;

  renderDesktop() {
    return (
      <header className={this.style.container}>
        <h1 className={this.style.title}>RACE LIST FILTERS</h1>
        <p className={this.style.subTitle}>
          Customize your race list using the filters below
        </p>
        <button className={this.style.close} onClick={this.props.closeComp}>
          <IconClose className={this.style.icon}/>
        </button>
      </header>
    );
  }

  renderMobile() {
    return (
      <header className={this.style.container}>
        <h1 className={this.style.title}>FILTER RACES</h1>
        <button className={this.style.close} onClick={this.props.closeComp}>
          <IconClose className={this.style.icon}/>
        </button>
      </header>
    );
  }

  render() {
    let Header;

    if (this.props.context === 'lhn') {
      Header = this.renderDesktop();
    }

    if (this.props.context === 'mhdr') {
      Header = this.renderMobile();
    }

    return (Header);
  }
}

export default RaceFilterHeader;
