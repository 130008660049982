// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import Button from '@tvg/ui-bootstrap/components/Buttons';
import { getContextStyle } from '../../factories/styleContext';
import styleDesktop from './style.desktop.css';
import styleMobile from './style.mobile.css';

type Props = {
  context: string,
  saveHandler: () => void,
  clearFilters: () => void,
};

class RaceFilterFooter extends Component {
  constructor(props: Props) {
    super(props);

    this.style = getContextStyle(this.props.context, styleDesktop, styleMobile);

    _.bindAll(this, ['saveClickHandler']);
  }

  saveClickHandler() {
    this.props.saveHandler();
  }

  props: Props;

  render() {
    return (
      <footer className={this.style.container}>
        <Button
          type="tertiary"
          size="big"
          onClick={this.props.clearFilters}
          content="CLEAR FILTERS"
        />
        <Button
          type="legacy"
          size="big"
          onClick={this.saveClickHandler}
          content="SAVE"
        />
      </footer>
    );
  }
}

export default RaceFilterFooter;
